import React /* useState */ from "react";
import { motion /* AnimatePresence */ } from "framer-motion";
import logo3 from "../../assets/logo3.svg";
/* import { ReactComponent as HamburgerIcon } from "../../assets/hamburger.svg";
import MediumButton from "./MediumButton";
import { ReactComponent as Arrow } from "../../assets/arrow.svg"; */

function NavBar() {
  /* const [isMenuOpen, setIsMenuOpen] = useState(false); */

  /* const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  function handleHover() {
    setIsHovered(true);
  }

  function handleLeave() {
    setIsHovered(false);
  }

  function handleHover2() {
    setIsHovered2(true);
  }

  function handleLeave2() {
    setIsHovered2(false);
  } */
  const variants = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 0 },
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  };
  return (
    <motion.nav
      className="flex z-10 fixed top-0 py-4 shadow-md border-b border-[#2e2e2e] justify-center w-full items-center  bg-[#181818]"
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <div className="flex max-w-[1170px] w-full z-30 justify-between px-5 sm:px-12 lg:px-5">
        {/* {<div
        className="font-bold text-xl hover:text-gray-400 duration-300 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        SaleszCash
      </div>} */}
        <a
          href="/"
          className="flex font-bold gap-3 items-center cursor-pointer"
        >
          <img src={logo3} className="w-[60px] flex -mt-[9px]" alt="logo"></img>
          <h2 className=" md:text-xl hover:text-gray-400 duration-300  ">
            SaleszCash
          </h2>
        </a>

        <div className="md:flex items-center gap-16 text-[15px] hidden ">
          {/* <h1 className="cursor-pointer hover:text-gray-400 duration-300">
            Rólunk
          </h1> */}
          {/* <h1 className="cursor-pointer hover:text-gray-400 duration-300">
          Valuta Tájékoztató
        </h1> */}
          <button className="font-bold text-[14px] bg-orange-500 px-4 py-2 rounded-[8px] hover:bg-orange-700 duration-300 ">
            <a href="/elerhetoseg">Elérhetőség</a>
          </button>
        </div>
        <button className="font-bold text-[12px] bg-orange-500 px-3 py-2 rounded-[8px] hover:bg-orange-700 duration-300 md:hidden">
          <a href="/elerhetoseg">Elérhetőség</a>
        </button>
        {/* <HamburgerIcon
          className="w-[24px] duration-300 h-[24px] cursor-pointer md:hidden"
          stroke={`${isHovered ? "#9ca3af" : "white"}`}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          onClick={handleMenuToggle}
        ></HamburgerIcon> */}
      </div>
      {/* <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="md:hidden absolute top-0 right-0 w-screen h-screen bg-black/50"
            onClick={handleMenuToggle}
          >
            <motion.div
              initial={{ y: -25 }}
              animate={{ y: 0, transition: { ease: "linear" } }}
              exit={{ opacity: 0, y: -25, transition: { ease: "linear" } }}
              className="absolute flex flex-col items-center px-10 pt-20 pb-10 w-screen bg-[#181818] border-b border-[#2e2e2e] shadow-md top-0 right-0 gap-5"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <h2
                className="text-[24px] flex items-center gap-2 font-bold cursor-pointer hover:text-gray-400 duration-300"
                onMouseEnter={handleHover2}
                onMouseLeave={handleLeave2}
                onClick={() => {
                  navigate("/rolunk");
                  handleMenuToggle();
                }}
              >
                Rólunk{" "}
                <Arrow
                  className={`w-[20px] h-[25px] duration-300 ${
                    isHovered2 && "translate-x-1"
                  }`}
                  stroke={!isHovered2 ? "white" : "#9ca3af"}
                  alt=""
                ></Arrow>
              </h2>
              <div className="w-[200px]">
                <MediumButton
                  text="Elérhetőség"
                  click={() => {
                    navigate("/elerhetoseg");
                    handleMenuToggle();
                  }}
                ></MediumButton>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence> */}

      {/* <div className=" lg:hidden">
        <Button
          text="Váltson Nálunk"
          click={() => {
            navigate("/elerhetoseg");
          }}
        ></Button>
      </div> */}
    </motion.nav>
  );
}

export default NavBar;
