import React from "react";
import inde from "../../../assets/indeicon.svg";
import Button from "../../universal/Button";
import HoverInfo from "./HoverInfo";
import { useCurrencyCalculator } from "../../../libs/useCurrencyCalculator";

function Calculator({ curr }) {

  const data = curr?.rates;
  const { calculateCurrencyChange, error, increaseAmountByStep, resultInfos, setAmount, serviceFee, sourceCurrency, targetCurrency, setTargetCurrency, setSourceCurrency } = useCurrencyCalculator(data);

  if (!curr) {
    return (
      <div className="flex flex-col">

        <div className=" flex flex-col w-full sm:w-[80%] mt-16 lg:mt-0  px-8 py-6 bg-[#181818]  rounded-[8px] border-[#2e2e2e] border shadoww lg:w-[430px] duration-300 gap-6 self-center">
          <div className="flex items-center justify-between">
            <h2 className="font-bold text-[20px]">Kalkulátor</h2>
            {/* <HoverInfo>Hiba történt a betöltés során...</HoverInfo> */}
            <HoverInfo>Fejlesztés alatt...</HoverInfo>
          </div>
          <div className="flex flex-col gap-4">
            <div className="h-[30px] w-[40%] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] w-[40%] rounded-[8px] bg-[#232323] animate-pulse mt-6"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] mt-3 bg-[#232323] animate-pulse"></div>
          </div>
        </div>
        <h3 className="max-w-[700px] lg:max-w-[400px] lg:text-left self-center lg:self-auto mt-4 text-center text-sm gry">*A számított összeg 0,9%, de maximum 40.000 Ft kezelési költséget tartalmaz.</h3>
      </div>
    );
  }


  return (
    <form className="flex flex-col" onSubmit={(e) => { e.preventDefault() }}>
      <div className=" flex flex-col w-full sm:w-[80%] mt-16 lg:mt-0  px-8 py-6 bg-[#1f1f1f] rounded-[8px] border-[#2e2e2e] border shadoww lg:w-[430px] duration-300 gap-6 self-center">
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-[20px]">Kalkulátor</h2>
          <HoverInfo>
            Ez a kalkulátor valós időben, a pillanatnyi árfolyamainkkal, a
            célvaluta egészére kerekítve számol.
            <br /> Kérjük, vegye figyelembe, hogy az árfolyamok változóak és csak
            tájékoztató jellegűek, mivel árfolyamaink naponta akár többször is
            változhatnak a piaci mozgásokkal párhuzamosan.
          </HoverInfo>
        </div>

        <h2>Erről a valutáról</h2>
        <select
          className="bg-[#242424] p-2 border border-[#2e2e2e] gry rounded-[4px] -mt-3"
          onChange={(e) => setSourceCurrency(e.target.value)}
          value={sourceCurrency}
        >
          {data?.map((currency) => (
            <option key={currency.name} value={currency.name}>
              {currency.text}
            </option>
          ))}
        </select>
        <input
          className="bg-[#242424] py-2 px-4 border border-[#2e2e2e] text-white ar rounded-[4px] -mt-2"
          placeholder="Átváltandó mennyiség..."
          type="number"
          min="0"
          step="1"
          onChange={(e) => setAmount(parseInt(e.target.value))}
        ></input>
        <h2>Erre a valutára</h2>
        <select
          className="bg-[#242424] p-2 border border-[#2e2e2e] gry rounded-[4px] -mt-3"
          onChange={(e) => setTargetCurrency(e.target.value)}
          value={targetCurrency}
        >
          {data?.map((currency) => (
            <option key={currency.name} value={currency.name}>
              {currency.text}
            </option>
          ))}
        </select>
        {error && <div className="font-bold ar text-[24px] tracking-tighter">
          {error}
        </div>}
        {typeof resultInfos.finalValue === "number" && !error && (
          <div>
            {typeof resultInfos.amount === 'number' && (
              <div className="gry ar words2">
                {resultInfos.formattedAmount.toLocaleString("hu-HU", {
                  useGrouping: true,
                  maximumFractionDigits: 0,
                })} {resultInfos.from} =
              </div>
            )}
            <div className="flex justify-between font-bold ar ">
              <div className="text-[24px] tracking-tighter words">
                {resultInfos.formattedFinalValue.toLocaleString("hu-HU", {
                  useGrouping: true,
                  maximumFractionDigits: 0,
                })} {resultInfos.to} {resultInfos.change && `+ ${resultInfos.change} HUF`}
              </div>
              <div></div>
            </div>
            {sourceCurrency === "HUF" && resultInfos.from === "HUF" && (
              <div className="flex justify-around gap-5 mt-3 select-none">
                <div
                  className="bg-[#242424]  border-[#2e2e2e] flex justify-center items-center border w-[50%] py-1 rounded-[8px] hover:bg-[#1a1a1a] duration-200 cursor-pointer"
                  onClick={() => {
                    increaseAmountByStep(-1)
                  }}
                >
                  <img
                    src={inde}
                    className=" h-[24px] rotate-180 select-none"
                    alt=""
                  ></img>
                </div>
                <div
                  className="bg-[#242424]  border-[#2e2e2e] flex justify-center items-center border w-[50%] py-1 rounded-[8px] hover:bg-[#1a1a1a] duration-200 cursor-pointer"
                  onClick={() => {
                    increaseAmountByStep(1)
                  }}
                >
                  <img src={inde} className=" h-[24px] select-none" alt=""></img>
                </div>
              </div>
            )}
          </div>
        )}
        <Button
          text="Átváltás"
          click={() => {
            calculateCurrencyChange();
          }}
        ></Button>
        {/* <img src={illustration}></img> */}
      </div>
      <h3 className="max-w-[700px] lg:max-w-[400px] lg:text-left self-center lg:self-auto mt-4 text-center text-sm gry">*A számított összeg {(serviceFee * 100).toFixed(1)}%, de maximum 40.000 Ft kezelési költséget tartalmaz.</h3>
    </form>
  );
}

export default Calculator;
