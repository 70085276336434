import axios from "axios";
import { supabase } from "../../../libs/supabase";

export async function fetchData() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_IP}/data`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchrates() {
  try {
    const { data: rates, error: ratesError } = await supabase
      .rpc("get_latest_rates")
      .select(
        "name:_name, buy:_buy, sell:_sell, link:_link, text:_text, step:_step, timestamp:_timestamps"
      );

    const latest_timestamp = getLatestTimestamp(rates);

    let { data: announcement, error: announcementError } = await supabase
      .from("announcement")
      .select("title, text")
      .single();

    return {
      announcement: announcement,
      timestamp: latest_timestamp,
      rates: rates,
    };
  } catch (error) {
    console.error(error);
  }
}

function getLatestTimestamp(data) {
  // Initialize with the first timestamp
  let latestTimestamp = new Date(data[0].timestamp);

  // Iterate through the array to find the latest timestamp
  for (let i = 1; i < data.length; i++) {
    const timestamp = new Date(data[i].timestamp);
    if (timestamp > latestTimestamp) {
      latestTimestamp = timestamp;
    }
  }

  return latestTimestamp.toISOString(); // Return the latest timestamp in ISO format
}
