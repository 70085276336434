import "./App.css";
import Contact from "./pages/CONTACT/Contact";
import Home from "./pages/HOME/Home";
import Login from "./pages/LOGIN/Login";
import Tajekoztato from "./pages/TAJEKOZTATO/Tajekoztato";
import ValuteChange from "./pages/VALUTACHANGE/ValuteChange";
import NavBar from "./pages/universal/NavBar";
import { Toaster } from "sonner";
import { Route, Routes } from "react-router-dom";

function App() {
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  return (
    <div className="lg:w-screen flex flex-col items-center max-w-[1170px]">
      <NavBar></NavBar>
      <Toaster theme="dark"/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/elerhetoseg" element={<Contact />} />
        <Route path="/bejelentkezes" element={<Login />} />
        <Route path="/valutachange" element={<ValuteChange />} />
        <Route path="/tajekoztato" element={<Tajekoztato />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
