import React, { useState } from "react";
import eur from "../../../assets/currency/eu.svg";
import usd from "../../../assets/currency/us.svg";
import gbp from "../../../assets/currency/gb.svg";
import chf from "../../../assets/currency/ch.svg";
import pln from "../../../assets/currency/pl.svg";
import czk from "../../../assets/currency/cz.svg";
import ron from "../../../assets/currency/ro.svg";
import { ReactComponent as Triangle } from "../../../assets/triangle.svg";
import HoverInfo from "./HoverInfo";
import { useInView } from "react-intersection-observer";

function CurrencTable({ data }) {
  const { ref, inView } = useInView({ threshold: 0.1 });

  if (inView) {
    document.body.classList.add("faq-in-view3");
  }

  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <div className="mt-20 w-full sm:w-full max-w-[860px] flex flex-col items-center px-5 sm:px-12 lg:px-5">
      <div className="mb-10  flex gap-3 max-w-[800px]">
        <h1 className=" font-bold text-[28px] sm:text-[42px] mb-3 text-center ">
          Árfolyamaink
        </h1>
        {/* 
        <p className="text-[18px]">
        A devizapiac ingatag természete miatt állandóan törekszünk az árak igazítására, hogy a lehető legjobb ajánlatot kínálhassuk ügyfeleinknek.
          Az Önök pénzének értéke számunkra kiemelkedően fontos. Éppen ezért, az
          árfolyamaink garantálják a legjobb árakat Szigetszentmiklóson.
        </p> */}
        <HoverInfo>
          {data ? (
            <div>Az alábbi árfolyamok az üzletünk aktuális árfolyamai.</div>
          ) : (
            <div>Hiba történt a betöltés során...</div>
          )}
        </HoverInfo>
      </div>

      <div
        className="border border-[#2e2e2e] rounded-[8px]  text-[18px] lg:text-[20px] justify-self-center shadoww w-full faq-item3"
        ref={ref}
      >
        <div className=" bg-[#232323] rounded-t-[8px] border-b border-[#2e2e2e] font-black py-4 flex ">
          <div className="flex w-full justify-center text-center">
            <h2 className="w-[30%] hidden sm:block">Valutanem</h2>
            <h2 className="w-[30%] sm:hidden">Valuta</h2>
            <h2 className="w-[30%]">Egység</h2>
            <h2 className="w-[30%]">Vétel</h2>
            <h2 className="w-[30%]">Eladás</h2>
          </div>
        </div>
        {data ? (
          data?.rates?.slice(1).map((curr, key) => {
            let currencyIcon;
            /* const percentageBuy = ((1 - curr.old_buy / curr.buy) * 100).toFixed(
              2
            );
            const percentageSell = (
              (1 - curr.old_sell / curr.sell) *
              100
            ).toFixed(2); */

            switch (curr.name) {
              case "EUR":
                currencyIcon = eur;
                break;
              case "USD":
                currencyIcon = usd;
                break;
              case "GBP":
                currencyIcon = gbp;
                break;
              case "CHF":
                currencyIcon = chf;
                break;
              case "PLN":
                currencyIcon = pln;
                break;
              case "CZK":
                currencyIcon = czk;
                break;
              case "RON":
                currencyIcon = ron;
                break;
              default:
                currencyIcon = null;
                break;
            }

            return (
              <div
                key={key}
                className={`flex w-full justify-center items-center text-center font-bold py-4 border-b-[#a8a8a8] text-[18px] lg:text-[20px] duration-300 ${
                  activeIndex === key && "bg-[#23232350] "
                }`}
                onMouseEnter={() => setActiveIndex(key)}
                onMouseLeave={() => setActiveIndex(null)}
              >
                <h2 className="w-[30%] flex justify-center items-center">
                  {currencyIcon && (
                    <img
                      src={currencyIcon}
                      className={`w-8 h-7 mr-2 rounded-[4px] `}
                      alt=""
                    />
                  )}
                  {curr.name}
                </h2>
                <h2 className="w-[30%] flex justify-center items-center">
                  (1)
                </h2>
                <h2 className="w-[30%] ar flex  justify-center items-center ">
                  <div className="flex gap-3 relative justify-center min-w-[40px]">
                    <span
                    /* className={`duration-300 ${
                        activeIndex === key &&
                        percentageBuy < 0 &&
                        "text-orange-500"
                      } ${
                        activeIndex === key &&
                        percentageBuy > 0 &&
                        "text-[#4169E1]"
                      }`} */
                    >
                      {curr.buy}
                    </span>
                    {/* {curr.buy !== curr.old_buy && (
                      <div className="hidden md:flex gap-1 items-center absolute -right-[70px] top-[5px] ">
                        <Triangle
                          className={`w-[10px] h-[10px] ${
                            percentageBuy < 0 && "rotate-180"
                          }`}
                          fill={percentageBuy < 0 ? "#F97316" : "#4169E1"}
                        ></Triangle>
                        <span
                          className={`text-[13px] ${
                            percentageBuy < 0
                              ? "text-orange-500"
                              : "text-[#4169E1]"
                          }`}
                        >{`${percentageBuy}%`}</span>
                      </div>
                    )} */}
                  </div>
                </h2>
                <h2 className="w-[30%] ar flex  justify-center items-center ">
                  <div className="flex gap-3 relative min-w-[40px] justify-center">
                    <span
                    /* className={`duration-300  ${
                        activeIndex === key &&
                        percentageSell < 0 &&
                        "text-orange-500"
                      } ${
                        activeIndex === key &&
                        percentageSell > 0 &&
                        "text-[#4169E1]"
                      }`} */
                    >
                      {curr.sell}
                    </span>
                    {/* {curr.sell !== curr.old_sell && (
                      <div className="hidden md:flex gap-1 items-center absolute -right-[70px] top-[5px]  ">
                        <Triangle
                          className={`w-[10px] h-[10px] ${
                            percentageSell < 0 && "rotate-180"
                          }`}
                          fill={percentageSell < 0 ? "#F97316" : "#4169E1"}
                        ></Triangle>
                        <span
                          className={`text-[13px] ${
                            percentageSell < 0
                              ? "text-orange-500"
                              : "text-[#4169E1]"
                          }`}
                        >{`${percentageSell}%`}</span>
                      </div>
                    )} */}
                  </div>
                </h2>
              </div>
            );
          })
        ) : (
          <div>
            <div className="flex items-center h-[60px] gap-8 mx-8 my-3">
              <div className="w-[30%] h-[50px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
              <div className="w-[66%] h-[30px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
            </div>
            <div className="flex items-center h-[60px] gap-8 mx-8 my-3">
              <div className="w-[30%] h-[50px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
              <div className="w-[66%] h-[30px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
            </div>
            <div className="flex items-center h-[60px] gap-8 mx-8 my-3">
              <div className="w-[30%] h-[50px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
              <div className="w-[66%] h-[30px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
            </div>
            <div className="flex items-center h-[60px] gap-8 mx-8 my-3">
              <div className="w-[30%] h-[50px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
              <div className="w-[66%] h-[30px] rounded-[8px] bg-[#232323] animate-pulse  items-center justify-center"></div>
            </div>
            <div className="flex items-center h-[60px] gap-8 mx-8 my-3">
              <div className="w-[30%] h-[50px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
              <div className="w-[66%] h-[30px] rounded-[8px] bg-[#232323] animate-pulse items-center justify-center"></div>
            </div>
          </div>
        )}
      </div>

      {data && (
        <div className="self-end gry mt-4 flex justify-center items-center gap-3 text-[14px] sm:text-[16px]">
          <div className="w-2 h-2 bg-orange-500 rounded-full  duration-300 animate-pulse ">
            <div className="w-2 h-2 bg-orange-500 rounded-full relative animate-ping"></div>
          </div>
          Utoljára frissítve:{" "}
          {new Date(data.timestamp).toLocaleString("hu-HU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </div>
      )}
    </div>
  );
}

export default CurrencTable;
