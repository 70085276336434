import React from "react";
import { motion } from "framer-motion";
import MediumButton from "../../universal/MediumButton";
import Calculator from "./Calculator";
import { useNavigate } from "react-router-dom";

function Hero({ data }) {
  const navigate = useNavigate();

  const leftDivVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="flex flex-col lg:flex-row lg:justify-between max-w-[1170px] px-5 sm:px-12 lg:px-5"
      initial="hidden"
      animate="visible"
      variants={leftDivVariants}
      transition={{ duration: 0.7 }}
    >
      <div className="lg:w-[50%] flex flex-col gap-6 lg:gap-8 items-center lg:items-start">
        <div className="flex flex-col items-center gap-8">
          <h1 className="font-bold text-[36px] sm:text-[52px] lg:text-[52px] lg:text-left text-center leading-none ">
            Valutaváltó Szigetszentmiklós központjában
          </h1>
          <div className=" items-center gap-3 flex lg:hidden ">
            {/* <div className="bg-[#101010] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <img src={price} className="w-[28px]" alt=""></img>
            </div> */}
            {/* <h2 className="font-bold text-[28px] sm:text-[32px] text-center lg:text-left capitalize text-red-500 ">
              Nincs kezelési költség
            </h2> */}
          </div>
        </div>

        <p className="text-[16px] text-center sm:text-[18px] lg:text-left max-w-[700px]">
          A devizapiac ingatag természetét figyelembevéve törekszünk az
          árfolyamainkat nap mint nap reális értékben megállapítani. Legyen Ön
          is az ügyfelünk, és fedezze fel szigetszentmiklósi pénzváltónkat.
        </p>
        <div className="flex items-center gap-12">
          <div className="w-[180px]">
            <MediumButton
              text="Váltson Nálunk"
              click={() => {
                navigate("/elerhetoseg");
              }}
            ></MediumButton>
          </div>
          <div className="lg:flex items-center gap-3 hidden ">
            {/* <div className="bg-[#101010] w-[36px] h-[36px] flex justify-center items-center rounded-full">
              <img src={price} className="w-[20px]" alt=""></img>
            </div> */}
            {/* <h2 className="font-bold text-[18px] lg:text-[26px] text-center lg:text-left capitalize text-red-500">
              Nincs kezelési költség
            </h2> */}
          </div>

          {/* <div className="flex items-center gap-3">
            <div className="bg-[#101010] w-[36px] h-[36px] flex justify-center items-center rounded-full">
              <img src={price} className="w-[20px]" alt=""></img>
            </div>
            
          </div> */}
        </div>
      </div>
      <Calculator curr={data}></Calculator>
    </motion.div>
  );
}

export default Hero;
