import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchrates } from "../HOME/components/fetchData";
import eur from "../../assets/currency/eu.svg";
import usd from "../../assets/currency/us.svg";
import gbp from "../../assets/currency/gb.svg";
import chf from "../../assets/currency/ch.svg";
import pln from "../../assets/currency/pl.svg";
import czk from "../../assets/currency/cz.svg";
import { toast } from "sonner";
import ron from "../../assets/currency/ro.svg";
import axios from "axios";
import { supabase } from "../../libs/supabase";

function ValuteChange() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [annInfo, setAnnInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [changingRates, setChangingRates] = useState(false);

  useEffect(() => {
    async function verifyUser() {
      const { data } = await supabase.auth.getSession();
      if (!data) {
        navigate("/bejelentkezes");
      }
    }

    verifyUser();
  }, [navigate]);

  useEffect(() => {
    async function getData() {
      const response = await fetchrates();
      setData(response.rates);
      setAnnInfo(response.announcement);
    }

    getData();
  }, []);

  const handleBuyChange = (currency, e) => {
    const newData = data.map((curr) => {
      if (curr.name === currency) {
        return {
          ...curr,
          buy: e.target.value,
        };
      }
      return curr;
    });
    setData(newData);
  };

  const handleChangeTitle = (event) => {
    const newAnnInfo = {
      ...annInfo,
      title: event.target.value,
    };
    setAnnInfo(newAnnInfo);
  };

  const handleChangeText = (event) => {
    const newAnnInfo2 = {
      ...annInfo,
      text: event.target.value,
    };
    setAnnInfo(newAnnInfo2);
  };

  const handleSellChange = (currency, e) => {
    const newData = data.map((curr) => {
      if (curr.name === currency) {
        return {
          ...curr,
          sell: e.target.value,
        };
      }
      return curr;
    });
    setData(newData);
  };

  const handleDelete = async () => {
    setAnnInfo({
      title: "",
      text: "",
    });

    const { error } = await supabase
      .from("announcement")
      .update({
        title: "",
        text: "",
      })
      .eq("id", 1);

    if (!error) {
      toast.success("A közlemény sikeresen törölve.");
      return;
    } else {
      toast.error("Hiba történt a közlemény törlése során.");
    }

    /* axios
      .put(
        `${process.env.REACT_APP_SERVER_IP}/announcement`,
        {
          title: "",
          text: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      }); */
  };

  const handleUpdateAnnouncement = async () => {
    const { error } = await supabase
      .from("announcement")
      .update(annInfo)
      .eq("id", 1);

    if (!error) {
      toast.success("A közlemény sikeresen mentve.");
      return;
    } else {
      toast.error("Hiba történt a közlemény mentése során.");
    }

    /* axios
      .put(`${process.env.REACT_APP_SERVER_IP}/announcement`, annInfo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      }); */
  };

  async function convertPricesToFloat() {
    setErrorMessage(null);
    setChangingRates(true);
    const updatedData = {
      rates: data.map((currency) => ({
        ...currency,
        buy: parseFloat(currency.buy),
        sell: parseFloat(currency.sell),
      })),
    };

    const { rates: current_rates } = await fetchrates();
    const newChanges = findDifferingElements(current_rates, updatedData.rates);

    if (newChanges.length !== 0) {
      const formatted = newChanges.map((currency) => ({
        buy: currency.buy ?? 0,
        sell: currency.sell ?? 0,
        currency: currency.name,
      }));

      const { error } = await supabase.from("rates").insert(formatted);

      if (!error) {
        toast.success("Sikeresen beállítottuk az új árfolyamokat.");
      } else {
        toast.error("Hiba történt...");
      }

      setChangingRates(false);
    } else {
      toast.error("Nincs árfolyamváltoztatás.");
      setChangingRates(false);
      return;
    }

    /* axios
      .put(`${process.env.REACT_APP_SERVER_IP}/data`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error updating exchange rates:", error);
        setErrorMessage(
          "Hiba történt. Próbáljatok ki és belépni újra. Ha nem sikerült, próbáljátok meg később."
        );
      }); */
  }
  return (
    <div className=" mx-5 sm:mx-12 my-20 mt-40 lg:mx-5 flex flex-col items-center w-full">
      <h1 className="text-[28px] sm:text-[40px] font-bold">
        Valuta Árfolyamállító
      </h1>
      <div className="border border-[#2e2e2e] rounded-[8px]  text-[18px] justify-self-center shadoww w-full max-w-[900px] mt-10">
        <div className=" bg-[#232323] rounded-t-[8px] font-black py-4 flex ">
          <div className="flex w-full justify-center text-center">
            <h2 className="w-[30%]">Valutanem</h2>
            <h2 className="w-[30%]">Vétel</h2>
            <h2 className="w-[30%]">Eladás</h2>
          </div>
        </div>
        {data &&
          data.slice(1).map((curr, key) => {
            let currencyIcon;

            switch (curr.name) {
              case "EUR":
                currencyIcon = eur;
                break;
              case "USD":
                currencyIcon = usd;
                break;
              case "GBP":
                currencyIcon = gbp;
                break;
              case "CHF":
                currencyIcon = chf;
                break;
              case "PLN":
                currencyIcon = pln;
                break;
              case "CZK":
                currencyIcon = czk;
                break;
              case "RON":
                currencyIcon = ron;
                break;
              default:
                currencyIcon = null;
                break;
            }

            return (
              <div
                key={key}
                className="flex w-full justify-center text-center font-bold py-4 border-b-[#a8a8a8] hover:bg-[#23232350] hover:text-orange-500 hover:text-opacity-100 duration-300"
              >
                <h2 className="w-[30%] flex justify-center">
                  {currencyIcon && (
                    <img
                      src={currencyIcon}
                      className="w-8 h-7 mr-2 rounded-[4px] "
                      alt=""
                    />
                  )}
                  {curr.name}
                </h2>
                <div className="w-[30%]">
                  <input
                    value={curr.buy}
                    type="number"
                    className="w-[100px] bg-[#242424] py-1 px-2 border border-[#2e2e2e] text-white ar rounded-[4px] text-[18px] -mt-2"
                    onChange={(event) => handleBuyChange(curr.name, event)}
                  ></input>
                </div>

                <div className="w-[30%]">
                  <input
                    value={curr.sell}
                    type="number"
                    className="w-[100px] bg-[#242424] py-1 px-2 border border-[#2e2e2e] text-white ar rounded-[4px] text-[18px] -mt-2"
                    onChange={(event) => handleSellChange(curr.name, event)}
                  ></input>
                </div>
              </div>
            );
          })}
      </div>
      <div className="w-[200px] mt-10">
        <button
          className="font-bold text-[16px] w-full bg-orange-500 px-4 py-3 rounded-[8px] hover:bg-orange-700 duration-300 "
          onClick={convertPricesToFloat}
          disabled={changingRates}
        >
          Beállítás
        </button>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      </div>
      {data && (
        <div className="flex flex-col items-center ">
          <h1 className="font-bold text-[28px] text-center sm:text-[40px] mt-20">
            Új Közlemény
          </h1>
          <h2 className="self-start font-bold text-[22px] mt-4">Cím</h2>
          <input
            value={annInfo.title}
            className=" w-[300px] sm:w-[600px] lg:w-[860px] bg-[#242424] py-1 px-2 border border-[#2e2e2e] text-white ar rounded-[4px] text-[18px] mt-4"
            onChange={handleChangeTitle}
          ></input>
          <h2 className="self-start font-bold text-[22px] mt-5">Szöveg</h2>
          <textarea
            value={annInfo.text}
            className=" w-[300px] sm:w-[600px] min-h-[200px] lg:w-[860px] bg-[#242424] py-1 px-2 border border-[#2e2e2e] text-white ar rounded-[4px] text-[18px] mt-4"
            onChange={handleChangeText}
          ></textarea>
          <div className="mt-5 flex justify-between w-full">
            <button
              className="font-bold text-[14px] bg-orange-500 px-4 py-2 rounded-[8px] hover:bg-orange-700 duration-300 "
              onClick={handleUpdateAnnouncement}
            >
              Közlemény Megosztása
            </button>
            <button
              className="font-bold text-[14px] bg-red-500 px-4 py-2 rounded-[8px] hover:bg-red-700 duration-300"
              onClick={handleDelete}
            >
              Közlemény Törlése
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ValuteChange;

function findDifferingElements(arr1, arr2) {
  const differingElements = [];

  // Iterate through the arrays and compare elements
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // Compare buy and sell values
    if (obj1.buy !== obj2.buy || obj1.sell !== obj2.sell) {
      differingElements.push(obj2);
    }
  }

  return differingElements;
}
