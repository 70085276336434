import React from "react";
import facebook from "../../assets/facebook.svg";

function Footer() {
  return (
    <div className="w-screen flex justify-center py-16 lg:py-8 border-t border-[#2a2a2a] bg-[#0b0b0b] shadow-md mt-40">
      <div className="w-full max-w-[1170px] px-5 flex  justify-between sm:px-12 lg:px-5">
        <div className="text-[16px] sm:text-[20px]  font-bold">
          <div className="flex mb-2">
            <a
              href="/"
              className=" lg:text-[24px] hover:text-gray-400 duration-300 cursor-pointer"
            >
              SaleszCash
            </a>
          </div>
          <h2 className="font-light text-[12px] sm:text-[14px] lg:text-[16px] gry">
            <h2 className="-mb-3 hover:text-white cursor-pointer font-bold duration-200 ">
              <a href="/tajekoztato">Tájékoztatók</a>
            </h2>
            <br></br>© {new Date().getFullYear()} - Salesz-Cash Kft.<br></br>MBH
            Bank Nyrt. függő kiemelt közvetítője. <br></br> Minden jog
            fenntartva.
          </h2>
          <a
            className="w-[36px] h-[36px] mt-3 hover:bg-orange-500/60 rounded-full flex justify-center items-center duration-300 cursor-pointer -ml-2"
            href="https://www.facebook.com/saleszcash"
          >
            <img
              src={facebook}
              className="w-[30px] h-[30px]"
              alt="facebook"
            ></img>
          </a>
        </div>
        <div className="flex text-right">
          <div className="">
            <h3 className="text-[16px] sm:text-[18px] lg:text-[22px] font-bold mb-2">
              Kapcsolatfelvétel
            </h3>
            <p className="gry text-[12px] sm:text-[14px] lg:text-[16px]">
              <a
                href="https://www.google.com/maps/place/Salesz-Cash+P%C3%A9nzv%C3%A1lt%C3%B3+Szigetszentmikl%C3%B3s/@47.3381255,19.033029,17z/data=!3m1!4b1!4m6!3m5!1s0x4741ef23bfb46479:0x4539bee0b9382130!8m2!3d47.3381255!4d19.0356039!16s%2Fg%2F11kbpfx3_k"
                target="_blank"
                rel="noreferrer"
                className="hover:text-orange-300 duration-300"
              >
                2310 Szigetszentmiklós
                <br />
                Bajcsy-Zsilinszky u. 57/2
                <br />
              </a>

              <a
                href="tel:+36702026956"
                className="hover:text-orange-300 duration-300"
              >
                +36 70 202 6956
              </a>
              <br />
              <a
                href="mailto:saleszcash57@gmail.com"
                className="hover:text-orange-300 duration-300"
                target="_blank"
                rel="noreferrer"
              >
                saleszcash57@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
