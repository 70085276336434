import React, { useState} from "react";
import faqData from "./FAQ.json";
import plus from "../../../assets/plus.svg";
import { useInView } from "react-intersection-observer";

function FAQ() {
  const { ref, inView } = useInView({ threshold: 0.1 });


  if(inView){
    document.body.classList.add("faq-in-view")
  }

  const [showAnswer, setShowAnswer] = useState({});

  const handleClick = (index) => {
    setShowAnswer({
      ...showAnswer,
      [index]: !showAnswer[index],
    });
  };

  return (
    <div className="flex flex-col mt-40 w-full max-w-[1170px] px-5 sm:px-12 lg:px-5" >
      <div className="">
        <h1 className="font-bold text-[32px] sm:text-[42px] text-center">
          Gyakran Ismételt Kérdések
        </h1>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-12" ref={ref}>
        {faqData.FAQ.map((item, index) => (
          <div
            key={index}
            className={`flex faq-item flex-col px-5 shadoww py-5 bg-[#1f1f1f] border border-[#2e2e2e] rounded-[8px] h-fit duration-200`}
          >
            <div
              className={`cursor-pointer flex justify-between`}
              onClick={() => handleClick(index)}
            >
              <h2 className="font-bold text-[18px] sm:text-[20px] select-none">
                {item.question}
              </h2>
              <img
                src={plus}
                className={`w-[24px] duration-300 select-none ${
                  showAnswer[index] && "rotate-[135deg]"
                }`}
                alt=""
              ></img>
            </div>
            <div
              className={`gry mt-2 modal ${
                showAnswer[index] && " open"
              } `}
            >
              {showAnswer[index] && item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
