import React, { useState } from "react";
import infoicon from "../../../assets/info.svg";
import { motion } from "framer-motion";

function HoverInfo({children}) {
  const [showNice, setShowNice] = useState(false);

  const handleMouseEnter = () => {
    setShowNice(true);
  };

  const handleMouseLeave = () => {
    setShowNice(false);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <div className="relative">
      <div
        className="hover:bg-white/20 rounded-full w-[34px] h-[34px] duration-300 cursor-pointer flex justify-center items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src={infoicon} className="w-[24px]" alt="info icon" />
      </div>
      {showNice && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={showNice ? "visible" : "hidden"}
          className="absolute shadow2 transform -translate-x-3/4 xl:-translate-x-1/2 top-full z-10 bg-[#181818] py-1 px-2 text-white text-sm rounded-lg w-[240px] "
        >
           {children}
        </motion.div>
      )}
    </div>
  );
}

export default HoverInfo;
