
export const serviceFee = 0.009;

export const mockRates = [
    {
        name: "HUF",
        buy: 1,
        sell: 1,
        link: "",
        text: "HUF - Magyar Forint",
        step: 5,
        timestamp: "2024-03-25T14:37:48.280012+00:00"
    },
    {
        name: "EUR",
        buy: 392,
        sell: 402,
        link: "euro",
        text: "EUR - Euró",
        step: 5,
        timestamp: "2024-10-21T07:51:17.938145+00:00"
    },
    {
        name: "USD",
        buy: 362,
        sell: 372,
        link: "usadollar",
        text: "USD - USA Dollár",
        step: 1,
        timestamp: "2024-10-17T06:50:00.997553+00:00"
    },
    {
        name: "GBP",
        buy: 472,
        sell: 484,
        link: "angolfont",
        text: "GBP - Angol Font",
        step: 5,
        timestamp: "2024-10-17T06:50:00.997553+00:00"
    },
    {
        name: "CHF",
        buy: 416,
        sell: 428,
        link: "svajcifrank",
        text: "CHF - Svájci Frank",
        step: 10,
        timestamp: "2024-10-11T06:25:59.870466+00:00"
    },
    {
        name: "PLN",
        buy: 92,
        sell: 98,
        link: "lengyelzloty",
        text: "PLN - Lengyel Zloty",
        step: 10,
        timestamp: "2024-10-07T07:00:04.419985+00:00"
    },
    {
        name: "CZK",
        buy: 15,
        sell: 17.5,
        link: "csehkorona",
        text: "CZK - Cseh Korona",
        step: 100,
        timestamp: "2024-04-03T11:00:46.611342+00:00"
    },
    {
        name: "RON",
        buy: 78,
        sell: 84,
        link: "romanlej",
        text: "RON - Román Lej",
        step: 1,
        timestamp: "2024-10-21T07:51:17.938145+00:00"
    }
];