import { useState } from "react";
import { serviceFee as serviceFeeConstant } from "./constants"

const resultInfoDefault = {
    //The from currency thats displayed in the result
    from: "",
    //The to currency thats displayed in the result
    to: "",
    //The amount thats displayed in the result
    amount: null,
    //The formatted amount. This is for displaying
    formattedAmount: null,
    //The final value that is displayed in the result
    finalValue: null,
    //The formatted final value. This is for displaying
    formattedFinalValue: null,
    //The change that is displayed in the result if the currency change happens between 2 non-HUF currencies (result is in HUF)
    change: null,
}

export const useCurrencyCalculator = (rates) => {
    const serviceFee = serviceFeeConstant;

    const [sourceCurrency, setSourceCurrency] = useState("EUR");
    const [targetCurrency, setTargetCurrency] = useState("HUF");
    //State inside the amount input
    const [amount, setAmount] = useState(0);
    //The final value that is calculated in the result
    const [resultInfos, setResultInfos] = useState(resultInfoDefault);

    //Any error messages that are displayed
    const [error, setError] = useState(null);

    //Helper Function to get the currency object by its name. Example: getCurrencyRate("EUR") returns the EUR object from the rates array
    /* {
        name: 'EUR',
        buy: 392,
        sell: 402,
        link: 'euro',
        text: 'EUR - Euró',
        step: 5,
        timestamp: '2024-10-21T07:51:17.938145+00:00'
      } */
    function getCurrencyRate(currencyName) {
        return rates?.find((currency) => currency.name === currencyName);
    }

    function getCurrencyStep(currencyName) {
        return getCurrencyRate(currencyName).step;
    }

    //Helper Function to calculate the buy rate of the currency
    function buyRate(currencyName) {
        return getCurrencyRate(currencyName).buy * (1 - serviceFee);
    }

    //Helper Function to calculate the sell rate of the currency
    function sellRate(currencyName) {
        return getCurrencyRate(currencyName).sell * (1 + serviceFee);
    }

    //Helper Function to calculate the HUF amount converted from a currency
    function calculateCurrencyToHuf(currencyAmount, currencyName) {
        const rate = buyRate(currencyName);

        return currencyAmount * rate;
    }

    //Helper Function to calculate the currency amount converted from HUF
    function calculateHufToCurrency(hufAmount, currencyName) {
        const rate = sellRate(currencyName);
        //Calculates the final value, rounding down to the nearest step
        const step = getCurrencyStep(currencyName);
        const converted = hufAmount / rate
        const roundedConverted = Math.floor(roundToNearestInteger(converted / step)) * step;

        return roundedConverted;
    }

    //Helper function to calculate the HUF amount after the HUF to currency change
    function calculateBackHufAmount(currencyAmount, currencyName) {
        const rate = sellRate(currencyName);
        return currencyAmount * rate
    }

    /**
     * Increases the amount by a specified step and recalculates the currency change.
     * 
     * @param {number} increaseBy - The multiplier for the step increase. Expects an integer.
     * 
     * This function increases the amount by the product of increaseBy, the currency step, and the sell rate.
     * It then updates the amount state and triggers the currency change calculation.
     * Should only be called if we have called calculateCurrencyChange() already
     */
    function increaseAmountByStep(increaseBy) {
        // Only applies if we change from HUF to another currency
        if (targetCurrency === "HUF" || sourceCurrency !== "HUF" || typeof amount !== "number" || typeof resultInfos.finalValue !== "number") {
            return;
        }

        const step = getCurrencyStep(targetCurrency);
        const rate = sellRate(targetCurrency);
        const newAmount = resultInfos.amount + (increaseBy * step * rate);

        setAmount(newAmount);

        const finalValue = calculateHufToCurrency(newAmount, targetCurrency);

        if (finalValue <= 0) {
            return;
        }
        const HUFamount = calculateBackHufAmount(finalValue, targetCurrency);
        setResultInfos({
            from: sourceCurrency,
            to: targetCurrency,
            amount: HUFamount,
            formattedAmount: calculateRounding(HUFamount),
            finalValue: finalValue,
            formattedFinalValue: finalValue,
            change: null,
        });
        setError(null);
        return;
    }


    //Main function to calculate the currency change. It updates the states
    function calculateCurrencyChange() {
        if (sourceCurrency === targetCurrency) {
            setError("Kérjük, válasszon különböző valutákat.")
            return;
        }

        if (amount <= 0) {
            setError("Kérjük, adjon meg egy érvényes összeget.")
            return;
        }

        if (sourceCurrency === "HUF") {
            const finalValue = calculateHufToCurrency(amount, targetCurrency);
            const HUFamount = calculateBackHufAmount(finalValue, targetCurrency);
            setResultInfos({
                from: sourceCurrency,
                to: targetCurrency,
                amount: HUFamount,
                formattedAmount: calculateRounding(HUFamount),
                finalValue: finalValue,
                formattedFinalValue: finalValue,
                change: null,
            });
            setError(null);
            return;
        }

        if (targetCurrency === "HUF") {
            const finalValue = calculateCurrencyToHuf(amount, sourceCurrency);
            setResultInfos({
                from: sourceCurrency,
                to: targetCurrency,
                amount: amount,
                formattedAmount: amount,
                finalValue: finalValue,
                formattedFinalValue: calculateRounding(finalValue),
                change: null,
            });
            setError(null);
            return;
        }

        if (targetCurrency !== "HUF" && sourceCurrency !== "HUF") {
            const valueToHuf = calculateCurrencyToHuf(amount, sourceCurrency);

            const valueFromHufToCurrency = calculateHufToCurrency(valueToHuf, targetCurrency);
            const convertedHUFamount = calculateBackHufAmount(valueFromHufToCurrency, targetCurrency);
            setResultInfos({
                from: sourceCurrency,
                to: targetCurrency,
                amount: amount,
                formattedAmount: amount,
                finalValue: valueFromHufToCurrency,
                formattedFinalValue: valueFromHufToCurrency,
                change: calculateRounding(valueToHuf - convertedHUFamount),
            });
            setError(null);
            return;
        }

    }


    return { sourceCurrency, setSourceCurrency, amount, setAmount, targetCurrency, setTargetCurrency, error, serviceFee, resultInfos, calculateCurrencyChange, increaseAmountByStep };
}

//Helper Function to round the number as the business logic requires (THIS JUST APPLIES TO HUF AMOUNTS)
export function calculateRounding(value) {
    const integerPart = Math.floor(value);
    const lastInteger = integerPart % 10;
    const decimalPart = value - integerPart;
    const lastIntegerWithDecimal = lastInteger + decimalPart;
    const nearest10 = Math.floor(integerPart / 10) * 10;

    if(lastIntegerWithDecimal <= 2.5){
        return nearest10;
    }

    if(lastIntegerWithDecimal <= 7.5){
        return nearest10 + 5;
    }

    return nearest10 + 10;
}

// Helper function to round a number to the nearest integer if it is within a specified threshold
export function roundToNearestInteger(value, threshold = 0.0001) {
    const decimalPart = value % 1;
    if (Math.abs(decimalPart) <= threshold || Math.abs(decimalPart - 1) <= threshold) {
        return Math.round(value);
    }
    return value;
}