import React from "react";
import DocFile from "./DocFile";
/* import doc1 from "../../../assets/docs/7. számú melléklet Adatkezelési tájékoztató (ügyféltérbe és honlapra).pdf"
import doc2 from "../../../assets/docs/1. számú melléklet Tájékoztató tábla és Hirdetmény (minta).pdf"
import doc3 from "../../../assets/docs/2. számú melléklet ÁSZF.pdf" */
import doc4 from "../../../assets/docs/6. számú melléklet Panaszkezelési szabályzat - tájékoztató.pdf"
import doc5 from "../../../assets/docs/6.1 Panaszbejelento_nyomtatvany_MNB.pdf"
import doc6 from "../../../assets/docs/6.2 Fogyasztoi_kerelem_MNB.pdf"
import doc7 from "../../../assets/docs/6.4 Meghatalmazás minta.pdf"
import doc8 from "../../../assets/docs/6.3 Fogyasztoi-kerelem-PBT.pdf"

function Docs() {
  window.scrollTo(0,0)
  return (
    <div className="grid grid-cols-1 gap-2  lg:grid-cols-2 sm:gap-4">
      <DocFile title="Panaszkezelési Szabályzat" doc={doc4}></DocFile>
      <DocFile title="Panaszbejelentő" doc={doc5}></DocFile>
      <DocFile title="Fogyasztói Kérelem MNB" doc={doc6}></DocFile>
      <DocFile title="Fogyasztói Kérelem PBT" doc={doc8}></DocFile>
      <DocFile title="Meghatalmazás" doc={doc7}></DocFile>
    </div>
  );
}

export default Docs;
