import React from 'react'

function Button({ text, click, ...props }) {
  return (
    <button className='font-bold text-[14px] bg-orange-500 px-4 py-2 rounded-[8px] hover:bg-orange-700 duration-300 ' onClick={click} {...props}>
      {text}
    </button>
  )
}

export default Button
