import React from "react";
import { useEffect, useState } from "react";
import { fetchrates } from "./components/fetchData";
import Hero from "./components/Hero";
import CurrencTable from "./components/CurrencTable";
import Features from "./components/Features";
import FAQ from "./components/FAQ";
import Footer from "../universal/Footer";
import Announcement from "./components/Announcement";

function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      const response2 = await fetchrates();
      setData(response2);
    }
    getData();
  }, []);
  return (
    <div className=" mt-32 sm:mt-40  flex flex-col items-center ">
      <Hero data={data}></Hero>
      <Announcement data={data}></Announcement>
      <CurrencTable data={data}></CurrencTable>
      <Features></Features>
      <FAQ></FAQ>
      {<Footer></Footer>}
    </div>
  );
}

export default Home;
