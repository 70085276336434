import React from "react";
import Info from "./components/Info";
import FAQ from "../HOME/components/FAQ";
import Footer from "../universal/Footer";
import background4 from "../../assets/valto4.jpg"

function Contact() {
  window.scrollTo(0,0)
  
  return (
    <div className="px-5 sm:px-12 lg:px-5  flex flex-col items-center w-full ">
      <h1
        className="text-[28px] px-5 pt-32 lg:pt-40 pb-20 bg-cover lg:bg-auto lg:pb-24 bg-center sm:text-[48px] lg:text-[64px] font-bold w-screen text-center grayscale border-b border-[#2e2e2e]"
        style={{backgroundImage: `url(${background4})`}}
      >
        Látogasson Meg Minket
      </h1>
      <Info></Info>
      <FAQ></FAQ>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
