import React from "react";

function ButtonButton({ text, click }) {
  return (
    <button
      className="font-bold text-[16px] w-full bg-orange-500 px-4 py-3 rounded-[8px] hover:bg-orange-700 duration-300 "
      onClick={click}
    >
      {text}
    </button>
  );
}

export default ButtonButton;
