import React from "react";
import Footer from "../universal/Footer";
import Docs from "./components/Docs";

function Tajekoztato() {
  return (
    <div className=" mt-10 sm:mt-20  flex flex-col items-center ">
      <h1 className="text-[28px] sm:text-[48px] lg:text-[60px] font-bold my-20">
        Tájékoztatók
      </h1>
      <Docs></Docs>
      <Footer></Footer>
    </div>
  );
}

export default Tajekoztato;
