import React from 'react'
import download from "../../../assets/download.svg"

function DocFile({title, doc}) {
  const handleDownload = () => {
    window.open(doc, '_blank');
  };
  return (
    <div className='w-full shadoww flex flex-row items-center justify-between px-5 py-3 bg-[#1F1F1F] border border-[#2e2e2e] sm:text-[22px] sm:py-4 sm:px-7 rounded-[8px] cursor-pointer font-bold gap-4 sm:gap-10' onClick={handleDownload}>
      {title}
      <img src={download} className='w-[30px] h-[24px]' alt='download'></img>
    </div>
  )
}

export default DocFile
