import React, { useState } from "react";
import Button from "../../universal/Button";
import axios from "axios";
import { motion } from "framer-motion";
import loading from "../../../assets/loading.svg";

function Info() {
  const leftDivVariants = {
    hidden: { y: 25, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState(null);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    setStatus("waiting");
    event.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/sendemail`,
        formData
      );
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      setStatus("success");
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus("error");
    }
  };

  return (
    <motion.div
      className="mt-20 flex lg:flex-row flex-col gap-28 lg:gap-32 justify between w-full px-5 sm:px-12 lg:px-0 "
      method="post"
      action="/send-email"
      initial="hidden"
      animate="visible"
      variants={leftDivVariants}
      transition={{ duration: 0.7 }}
    >
      <form
        className="lg:w-1/3 w-full flex flex-col gap-6 order-1 lg:order-1 "
        onSubmit={handleSubmit}
      >
        <div>
          <h2 className="text-[24px] font-bold mb-2">Küldjön egy üzenetet!</h2>
          <p className="gry">
            Örömmel válaszolunk bármilyen kérdésére, csak küldjön egy üzenet, és
            igyekszünk válaszolni 24 órán belül.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-[18px]">Név</h3>
          <input
            className="w-full lessdark  p-3"
            placeholder="pl.: Nagy István"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          ></input>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-[18px]">Email</h3>
          <input
            className="w-full lessdark text-opacity-40  p-3"
            placeholder="pl.: nagyistvan@gmail.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          ></input>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold text-[18px]">Tárgy</h3>
          <input
            className="w-full lessdark  p-3"
            placeholder="pl.: Árfolyam érdeklődés"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          ></input>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-bold text-[18px]">Szöveg</h3>
          <textarea
            className="w-full lessdark  p-3 mb-2"
            placeholder="Az üzenete..."
            rows="4"
            style={{ resize: "none" }}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <Button text="Küldés" type="submit"></Button>
        </div>
        {status === "success" ? (
          <p className=" self-center">Az üzenet sikeresen elküldve.</p>
        ) : status === "error" ? (
          <p className="text-orange-500 self-center">Hiba...</p>
        ) : (
          status === "waiting" && (
            <img
              src={loading}
              className="w-[32px] self-center animate-spin"
              alt=""
            ></img>
          )
        )}
      </form>
      <div className="lg:w-2/3 w-full lg:order-2">
        <iframe
          title="Map"
          className="w-full rounded-[12px]"
          height="500"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2310%20Szigetszentmikl%C3%B3s,%20Bajcsy-Zsilinszky%20u.%2057+(Salesz-Cash%20KFT)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          loading="lazy"
        ></iframe>
        <div className="flex flex-col sm:flex-row  gap-10 text-[18px]   sm:text-[18px]">
          <div className=" mt-6 flex flex-col gap-2 sm:w-1/2">
            <h2 className="text-[24px] font-bold">
              Üzletünk elérhetősége
            </h2>
            <a
              className="gry mt-2 hover:text-orange-300 duration-300"
              href="https://www.google.com/maps/place/Salesz-Cash+P%C3%A9nzv%C3%A1lt%C3%B3+Szigetszentmikl%C3%B3s/@47.3381255,19.033029,17z/data=!3m1!4b1!4m6!3m5!1s0x4741ef23bfb46479:0x4539bee0b9382130!8m2!3d47.3381255!4d19.0356039!16s%2Fg%2F11kbpfx3_k"
              target="_blank"
              rel="noreferrer"
            >
              2310 Szigetszentmiklós,
              <br /> Bajcsy-Zsilinszky u. 57/2
            </a>
            <a
              href="mailto:saleszcash57@gmail.com"
              className="hover:text-orange-300 duration-300 gry"
              target="_blank"
              rel="noreferrer"
            >
              saleszcash57@gmail.com
            </a>
            <h3 className="gry mt-3">Sallai Gábor (üzletvezető)</h3>
            <h3 className="gry -mt-2">
              <a
                href="tel:+36702026956"
                className="hover:text-orange-300 duration-300"
              >
                +36 70 202 6956
              </a>
            </h3>
          </div>
          <div className=" mt-6 flex flex-col gap-2 sm:w-1/2 text-[18px] sm:text-right">
            <h2 className="text-[24px] font-bold">
              Nyitvatartás
            </h2>
            <h3 className="gry mt-2 ">Hetfő-Péntek: 8-17 óra</h3>
            <h3 className="gry ">Szombat: 8-12 óra</h3>
            <h3 className="gry ">Vasárnap: zárva</h3>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Info;
